import { Box, Button, ChakraProvider, FormLabel, Heading, Input, Select, Text, Textarea } from "@chakra-ui/react";
import * as React from "react";
import { createRoot } from "react-dom/client";
import InnerHTML from 'dangerously-set-html-content'

import "./styles.css";

const advertiserSnippet = (advId: number) =>
`<script>
!function(p,i,x,e,l,s)
{p.slq||(s=p.slq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments)},s.queue=[],l=i.createElement(x),l.async=!0,l.src='https://v1.slise.xyz/scripts/pix3l.js',l.id='slise-pix3l',l.setAttribute('data-slise-adv-id',e),e=i.getElementsByTagName(x)[0],e.parentNode.insertBefore(l,e))}(window,document,'script','adv-${advId}');
</script>`

const publisherSnippetReact = (pubId: number, slot: string, w: number, h: number) =>
  `<SliseAd slotId="${slot}" pub="pub-${pubId}" format="${w}x${h}" style={{ width: '${w}px', height: '${h}px' }} />`

const publisherSnippetJs = (pubId: number, slot: string, w: number, h: number) =>
`<script async src="https://v1.slise.xyz/scripts/embed.js"></script>
<ins
  class="adsbyslise"
  style="display:inline-block;width:${w}px;height:${h}px"
  data-ad-slot="${slot}"
  data-ad-pub="pub-${pubId}"
  data-ad-format="${w}x${h}"
></ins>
<script>;(adsbyslise=window.adsbyslise||[]).push({slot:"${slot}"});window.adsbyslisesync&&window.adsbyslisesync();</script>`

const formats = [
  "728x90",
  "270x90",
]

function App() {
  let [advIdText, setAdvIdText] = React.useState("")
  
  let [pubIdText, setPubIdText] = React.useState("")
  let [pubSlot, setPubSlot] = React.useState("")
  let [pubSlotFormat, setPubSlotFormat] = React.useState(formats[0])
  
  let [showSnippetIf, setShowSnippetIf] = React.useState("")

  let advId = parseInt(advIdText, 10)
  let pubId = parseInt(pubIdText, 10)

  let [w,h] = pubSlotFormat.split('x').map(x => parseInt(x))
  
  let publisherSnippetEmbed = pubId && pubSlot && publisherSnippetJs(pubId, pubSlot, w, h)
  let showPublisherAd = showSnippetIf == publisherSnippetEmbed
  
  React.useEffect(() =>
  {
    if (!showPublisherAd)
      setShowSnippetIf("")
  }, [pubIdText, pubSlot])

  return (
    <div>
      <Heading>Snippets</Heading>

      <Box mt="20">
        <FormLabel>Advertiser</FormLabel>
        <Input value={advIdText} onChange={e => setAdvIdText(e.currentTarget.value)} placeholder="12345" />
          {advId
            ? <><FormLabel>pix3l:</FormLabel><pre><Textarea
                rows={4}
                whiteSpace="pre"
                overflowWrap="normal"
                overflowX="scroll"
                onClick={e => e.currentTarget.select()}
                value={advertiserSnippet(advId)}
              /></pre></>
            : <Text>Enter correct Advertiser ID</Text>
          }
      </Box>
      <Box mt="20" mb="20">
        <hr/>
        </Box>
      <Box>
      <FormLabel>Publisher ID</FormLabel>
      <Input value={pubIdText} onChange={e => setPubIdText(e.currentTarget.value)} placeholder="12345" />
      <FormLabel>Format</FormLabel>
      <Select value={pubSlotFormat} onChange={e => setPubSlotFormat(e.currentTarget.value)}>
        {formats.map((x,i) => <option key={i}>{x}</option>)}
      </Select>
      <FormLabel>Ad slot name</FormLabel>
      <Input value={pubSlot} onChange={e => setPubSlot(e.currentTarget.value)} placeholder="e.g. leaderboard" />
          {pubId
            ? pubSlot
              ? <>
                Example: <br/>
                {showPublisherAd
                  ? <InnerHTML html={publisherSnippetEmbed} />
                  : <Button onClick={() => setShowSnippetIf(publisherSnippetEmbed)}>Show example ad</Button>
                }
                <br/>
                <FormLabel>React:</FormLabel>
                <pre><Textarea
                  rows={1}
                  whiteSpace="pre"
                  overflowWrap="normal"
                  overflowX="scroll"
                  onClick={e => e.currentTarget.select()}
                  value={publisherSnippetReact(pubId, pubSlot, w, h)}
                /></pre>
                <FormLabel>JS:</FormLabel>
                <pre><Textarea
                  rows={9}
                  whiteSpace="pre"
                  overflowWrap="normal"
                  overflowX="scroll"
                  onClick={e => e.currentTarget.select()}
                  value={publisherSnippetJs(pubId, pubSlot, w, h)}
                /></pre>
              </>
              : <Text>Enter correct Slot Name</Text>
            : <Text>Enter correct Publisher ID</Text>
          }
      </Box>
    </div>
  )
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
